import React from 'react';
import './index.css';

const newsList = [
  { 'date': '2023年12月19日', 'title': '招贤纳士', 'link': '/news/20231219', 'img': "/IMG_202312190000.png" },
  { 'date': '2023年11月30日', 'title': '首届趣味运动会', 'link': '/news/20231130', 'img': "/IMG_202311300000.jpg" },
  { 'date': '2023年11月21日', 'title': '消防演练总结', 'link': '/news/20231121', 'img': "/IMG_202311210000.jpg" },
]

const NewsCenter = () => {
  return (
    <div className='layout-newsdata'>
      <h1 className='layout-newsdata-title'>
        新闻动态
      </h1>
      <div className='layout-newsdata-container'>
        {
          newsList.map((item, key) => {
            return (
              <div className='layout-newsdata-item' key={key}>
                <div className='layout-newsdata-item-pic'>
                  <a href={item.link}>
                    <img src={process.env.PUBLIC_URL + item.img} alt={item.img} />
                  </a>
                </div>
                <div className='layout-newsdata-item-detail'>
                  <div className="layout-newsdata-item-title">
                    <h3>
                      <a href={item.link}>
                        {item.text || item.title}
                      </a>
                    </h3>
                  </div>
                  <div className="layout-newsdata-item-tag">
                    <a href={item.link}>
                      {item.date}
                    </a>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default NewsCenter;
