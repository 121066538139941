import React from 'react';
import './index.css';

const services = [
    { 'title': '单晶炉热场', 'image': process.env.PUBLIC_URL + '/img-service-1.png' },
    { 'title': '热处理炉', 'image': process.env.PUBLIC_URL + '/img-service-2.png' },
    { 'title': 'SIC结晶', 'image': process.env.PUBLIC_URL + '/img-service-3.png' },
    { 'title': '蓝宝石结晶', 'image': process.env.PUBLIC_URL + '/img-service-4.png' },
    { 'title': '光纤', 'image': process.env.PUBLIC_URL + '/img-service-5.png' },
];

const Service = () => {
    return (
        <div className='layout-service'>
            <h1>应用领域</h1>
            <div className="layout-service-container">
                <div className="layout-service-list">
                    {
                        services.map((item, index) => {
                            return (
                                <div className="layout-service-element" key={index}>
                                    <div className='layout-service-element-title'>
                                        {item.title}
                                    </div>
                                    <div className="layout-service-element-image">
                                        <img src={item.image} alt='img' />
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="layout-service-element">
                        <p>
                            在各个应用领域，我们拥有丰富的应用经验。
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service;
