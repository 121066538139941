import React from 'react';
import { useTranslation } from "react-i18next";
import './index.css';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="office">
        <h1>{t('footer.office')}</h1>
        <ul>
          <li><img className='fas' src='/phone-fill.png' alt='img' /><span>+86 0571 8614 3260</span></li>
          <li><img className='fas' src='/envelope.png' alt='img' /><span>support@insulcarb.com</span></li>
          <li></li>
          <li><img className='fas' src='/map-marker.png' alt='img' /><span>{t('footer.address')}</span></li>
        </ul>
      </div>
      <div className="divider"></div>
      <div className="copyright">
        <p><span>浙江盈烁新材料有限责任公司</span><span>© 2023 - 2024</span></p>
        <p><span><a href="https://beian.miit.gov.cn/" target='_blank' rel="noreferrer">浙ICP备2023042011号-1</a></span></p>
      </div>
    </div>
  )
}

export default Footer;
