import React, { useState} from 'react';
import './swipe.css';

const imageList = ['background-home-1.jpg', 'background-home-2.jpg', 'background-home-3.jpg'];

const Swipe = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((currentIndex + 1) % imageList.length);
    }

    const preSlide = () => {
        setCurrentIndex((currentIndex - 1 + imageList.length) % imageList.length);
    }

  return (
    <div id="slider">
        <div className="slider-container">
            {
                imageList.map((image, index)=>{
                    return (
                        <div key={index} className={`slider-image ${index === currentIndex ? 'active' : ''}`} style={{ backgroundImage: `url(${ process.env.PUBLIC_URL  + "/" + image })`}}>
                        </div>
                    )
                })
            }
        </div>
        <div className="overlay"></div>
        <button className='slider-prev-button' onClick={preSlide}>
            <i className="slider-arrow"></i>
        </button>
        <button className="slider-next-button" onClick={nextSlide}>
        <i className="slider-arrow"></i>
        </button>
    </div>
  )
}

export default Swipe;
