import React from 'react';
import './index.css';

const ctx = [
  "浙江盈烁新材料有限责任公司，注册资金2500万，总投资1亿元，成立于2023年2月3日，位于浙江省杭州市临平区。我们地理环境优越，交通便利。公司由生产制造车间及办公楼组成，经营面积达9000多平方。专注于研发、生产、销售各种半导体用保温碳毡。公司由政府招商引资进驻，立项百吨碳纤维保温材料的加工制造。该项目由具有十余年经验的数位资深业内专家发起，采用国际先进技术，结合本土供应链,致力于为国内半导体行业替代目前的进口材料做出贡献。作为国家战略资源的产业链上游环节之一，我司产品还可应用于光伏、碳化硅、蓝宝石等行业。",
  "公司定位于满足国内半导体高端市场不断增长的客户需求及品质要求。力争打破国际市场垄断。为此我们设定了企业的愿景、使命、价值观以及企业的目标、精神、质量方针、经营理念。我们的愿景是成为全球领先的保温材料供应商；使命是以创新和卓越的保温材料技术，满足客户需求并推动行业进步。我们共同认可的价值观为廉洁、谦虚、务实、钻研、团结。",
  "公司自建立以来，十分注重自身素质的提高。近一年来公司在市场准入、专利申请以及资质认证方面都积极开展相关工作，并取得实质性进展。同时我们始终秉持“以人为本”的理念，关心员工成长和生活。此外，我们以“创新驱动”为引领，不断推动企业向前发展。我们以“诚信共赢”为原则，与客户、合作伙伴建立长期稳定的合作关系。目前公司的供应链已涵盖近300余家合作方。作为制造型企业，公司在运营发展的同时更为注重安全工作的开展，我们秉承“安全第一、预防为主、综合治理”的安全方针，全方面、全方位、全员化打造公司的安全管理体系，为企业的长足健康运营奠定基础。",
  "在未来的日子里，我们将继续发挥自身优势，吸纳人才，积极拓展市场，不断提高产品质量和服务水平，为客户提供更优质的产品和服务。同时，我们也希望与各界朋友携手合作，共创辉煌未来！",
]

const bizList = [
  { "img": '/IMG_2023122900.png', "title": "我们的企业目标", "text": "提供一流的产品和服务，企业争创一流。" },
  { "img": '/IMG_2023122900.png', "title": "我们的企业精神", "text": "专业，创新，合作，共赢。" },
  { "img": '/IMG_2023122900.png', "title": "我们的质量方针", "text": "品质力臻卓越、服务锲而不舍。" },
  { "img": '/IMG_2023122900.png', "title": "我们的企业经营理念", "text": <p>通过专业精神和不断创新的理念，为市场提供有竞争力的产品，来实现合理利润；<br /> 改善员工福利，回馈社会，回馈股东。</p> },
]

const About = () => {
  return (
    <div className='about-layout'>
      <h1>公司简介</h1>
      <div className="about-layout-container">
        <div className="about-layout-content">
          {
            ctx.map((item, key) => {
              return (
                <p key={key}>{item}</p>
              )
            })
          }
          <div className="about-layout-content-list">
            {
              bizList.map((item, index) => {
                return (
                  <div className="about-layout-content-item" key={index}>
                    <div className="about-layout-content-item-title">
                      <img src={item.img} alt="img" />
                      <h2>{item.title}</h2>
                    </div>
                    <div className="about-layout-content-item-detail">
                      {item.text}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default About;
