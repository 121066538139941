import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    lng: localStorage.getItem('language') || 'zh',
    fallbackLng: 'zh',
    detection: {
        caches: ['localStorage', 'sessionStorage', 'cookie'],
    },
    interpolation: {
        escapeValue: false,
    },
    resources: {
        "en": {
            translation: {
                "header": {
                    "home": "Home",
                    "about": "About",
                    "product": "Product",
                    "service": "Services",
                    "news": "News",
                    "contact": "Contact",
                },
                "footer": {
                    "office": "Hangzhou Office",
                    "address": "Linping Avenue No. 596, Linping District",
                }
            },
        },
        "zh": {
            translation: {
                "header": {
                    "home": "首页",
                    "about": "关于我们",
                    "product": "产品信息",
                    "service": "应用领域",
                    "news": "新闻动态",
                    "contact": "联系我们",
                },
                "footer": {
                    "office": "杭州办公室",
                    "address": "浙江省杭州市临平区临平大道596号",
                }
            },
        },
    },
});

export default i18n;