import React from 'react';
import { Routes, Route } from "react-router-dom";
import Header from '../pages/layout/header';
import Footer from '../pages/layout/footer';
import Home from '../pages/home';
import About from '../pages/about';
import Product from '../pages/product';
import Service from '../pages/service';
import News from '../pages/news';
import NewsCenter from '../pages/newscenter';

const Router = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/product" element={<Product />} />
        <Route path="/service" element={<Service />} />
        <Route path="/news" element={<NewsCenter />} />
        <Route path="/news/:id" element={<News />} />
      </Routes>
      <Footer />
    </>
  )
}

export default Router;
