import React from 'react';
import { useParams } from 'react-router-dom';
import './index.css';

const benefitsImages = [
  { 'Image': '/IMG_20231219000001.png', 'Text': '全勤奖' },
  { 'Image': '/IMG_20231219000002.png', 'Text': '绩效奖金' },
  { 'Image': '/IMG_20231219000003.png', 'Text': '五险一金' },
  { 'Image': '/IMG_20231219000005.png', 'Text': '带薪年假' },
  { 'Image': '/IMG_20231219000006.png', 'Text': '员工食堂' },
  { 'Image': '/IMG_20231219000007.png', 'Text': '节日福利' },
  { 'Image': '/IMG_20231219000008.png', 'Text': '生日福利' },
  { 'Image': '/IMG_20231219000009.png', 'Text': '通讯补助' },
  { 'Image': '/IMG_20231219000010.png', 'Text': '出差补助' },
  { 'Image': '/IMG_20231219000011.png', 'Text': '培训机会' },
]

const News = () => {
  const { id } = useParams();
  let content;
  switch (id) {
    case '20231121':
      content = <News20231121 title={'消防演练总结'} />;
      break;
    case '20231130':
      content = <News20231130 title={'首届趣味运动会'} />;
      break;
    case '20231219':
      content = <News20231219 title={'招贤纳士'} />;
      break;
    default:
      content = <>404 NOT FOUND</>;
  }
  return (
    <div className='layout-news'>
      {content}
    </div>
  )
}

const News20231219 = ({ title }) => {
  return (
    <div className='layout-posi-container'>
      <div className="layout-posi-header"><h1>{title}</h1></div>
      <div className="layout-posi-benefits">
        <div className="layout-posi-benefits-title">福利待遇</div>
        <div className="layout-posi-benefits-content">
          {
            benefitsImages.map((item, key) => {
              return (
                <div className="layout-posi-benefits-item" key={key}>
                  <div className="layout-posi-benefits-item-pic">
                    <img src={item.Image} alt={item.Text} />
                  </div>
                  <div className="layout-posi-benefits-item-title">{item.Text}</div>
                </div>
              )
            })
          }
        </div>
      </div>
      <div className='layout-posi-list'>
        <div className='layout-posi-list-card'>
          <div className='layout-posi-list-card-header'>
            <div className='layout-posi-list-card-header-title'>技术工程师</div>
            <div className='layout-posi-list-card-header-detail'>1 人&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;本科以上&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;经验不限</div>
          </div>
          <div className='layout-posi-list-card-content'>
            <div className="layout-posi-list-card-content-title">岗位职责</div>
            <div className='layout-posi-list-card-content-detail'>
              1、收集相关产品技术信息，提出产品改进建议和新产品开发建议<br />
              2、参与或主导产品开发<br />
              3、独立进行产品实验设计及产品应用开发<br />
              4、能够按照客户的要求及现场实际对产品进行改善，针对性的进行数据分析及改善计划<br />
              5、对客户投诉中的技术问题进行回答并解决<br />
              6、专利申请相关工作<br />
              7、能够按照对自身的工作进行总结，及时有效的沟通<br />
            </div>
            <div className="layout-posi-list-card-content-title">任职要求</div>
            <div className='layout-posi-list-card-content-detail'>
              1、统招本科及以上学历，材料类、化学类、高分子材料专业<br />
              2、具备各类热固性树脂材料性能相关知识<br />
              3、熟悉材料的评价及测试方法<br />
              4、具备复合材料和纤维方面的知识<br />
              5、有较强的自主学习能力<br />
              6、具备良好的逻辑分析能力，沟通协调能力、统筹汇报能力<br />
              7、身体健康，性格乐观，积极向上<br />
            </div>
            <button className="layout-posi-list-card-content-phone">联系电话：0571 - 8614 3260</button>
          </div>
        </div>
        <div className='layout-posi-list-card'>
          <div className='layout-posi-list-card-header'>
            <div className='layout-posi-list-card-header-title'>工艺工程师</div>
            <div className='layout-posi-list-card-header-detail'>1 人&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;本科以上&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;经验不限</div>
          </div>
          <div className='layout-posi-list-card-content'>
            <div className="layout-posi-list-card-content-title">岗位职责</div>
            <div className='layout-posi-list-card-content-detail'>
              1、根据生产实际进行标准化设计，并形成文件<br />
              2、根据现场实际对生产工序手法及工具进行优化<br />
              3、解决生产过程中工艺方面的问题，并进行总结改善<br />
              4、对工艺参数定期进行统计分析<br />
              5、针对现场工序能力定期进行检讨，并提供改善计划<br />
              6、负责现场工艺变更验证，制定验证计划并落实<br />
              7、配合进行产品的优化及改进<br />
            </div>
            <div className="layout-posi-list-card-content-title">任职要求</div>
            <div className='layout-posi-list-card-content-detail'>
              1、全日制本科，材料类或者理工类专业<br />
              2、逻辑能力强、性格乐观，积极向上，自主学习能力强<br />
              3、具备较高的团队协作能力跟沟通能力<br />
              4、具备一定的CAD制图能力<br />
              5、具备一定的数据分析跟文档处理能力<br />
            </div>
            <button className="layout-posi-list-card-content-phone">联系电话：0571 - 8614 3260</button>
          </div>
        </div>
        <div className='layout-posi-list-card'>
          <div className='layout-posi-list-card-header'>
            <div className='layout-posi-list-card-header-title'>制造副经理（设备）/设备主管</div>
            <div className='layout-posi-list-card-header-detail'>1 人&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;专科以上&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;经验5年以上</div>
          </div>
          <div className='layout-posi-list-card-content'>
            <div className="layout-posi-list-card-content-title">岗位职责</div>
            <div className='layout-posi-list-card-content-detail'>
              1、协助生产部经理做好公司日常设备管理工作，组织、协调、指导、监督公司设备的采购、维护、维修、运行等工作。<br />
              2、负责设备动力管理工作，对公司内设备的资产状况和运行状况实施监督管理；<br />
              3、负责设备安装，安排设备维修保养计划，设备备件采购并协调各事业部组织好日常设备保养维修等；<br />
              4、负责协助经理做好本部门日常管理工作及部门奖惩工作，确保所承担的各项经济指标和工作任务的完成，组织领导下属严格执行公司决定及相关工作程序标准；<br />
              5、负责全公司能源供应和计量，以及公司电力设施的运行管理；<br />
              6、负责特种设备的安全管理，质量环境管理体系的有关工作。<br />
            </div>
            <div className="layout-posi-list-card-content-title">任职要求</div>
            <div className='layout-posi-list-card-content-detail'>
              1、经济管理、企业管理、工商管理或机电专业大专以上学历。<br />
              2、受过生产管理、设备管理、财务管理及合同法规等方面的培训。<br />
              3、5年以上制造生产管理工作经验，有设备管理、维修经验者优先考虑。<br />
              4、具有较强的沟通协调能力、文字表达能力、语言表达能力和学习能力；<br />
              5、具有较强的创新意识、成本意识、质量意识、环境意识和法律意识；<br />
              6、熟悉相关专业知识、财务管理、《合同法》等法律法规及公司的各项规章制度；<br />
              7、熟练掌握计算机，办公软件。<br />
            </div>
            <button className="layout-posi-list-card-content-phone">联系电话：0571 - 8614 3260</button>
          </div>
        </div>
      </div>
    </div>
  )
}

const News20231121 = ({ title }) => {
  return (
    <div className="layout-news-content">
      <h1>{title}</h1>
      <p>
        2023 年11 月21 日下午1 点20 分，开投物业针对我司员工组织了一次消防应急预案实战演练。整个演练共分为现场模拟火灾疏散急救、初起火灾灭火器实射演练与室外消火栓操作演练三个过程，整个演练历时半小时，涉及人员50 余人。
      </p>
      <div className="layout-news-center">
        <img src={process.env.PUBLIC_URL + "/IMG_202311210001.jpg"} style={{ "width": "500px", "margin": "0 auto" }} alt="IMG" />
      </div>
      <p>
        开投物业针对本次应急预案演练，做了充分的准备，成立了临时现场指挥组，统一指挥专业抢险组、疏散解救组与后勤保障组协同作战。本次应急预案演练配置消防员2 人、消防服4 套、灭火器28 瓶、消防斧1 把、消防扳手1 把、防毒面具4 套、水带2 卷、枪头2 根、逃生绳1 根。充备的资源为本次应急预案的演练，真正做到了保障到位。
      </p>
      <div className="layout-news-center">
        <img src={process.env.PUBLIC_URL + "/IMG_202311210002.jpg"} style={{ "width": "500px", "margin": "0 auto" }} alt="IMG" />
      </div>
      <p>
        本次应急预案演练，从火情发生到疏散解救、扑灭火源共历时20 分钟，每个环节均有现场指导，使得每位员工体验其中，增加了对火情扑救，如何疏散，如何自救和逃生的知识储备，在一定程度上促进我们的员工有能力做到及时、有效地控制火情的扩大、保障人身安全，避免财产损失，同时也学会在火情发生要冷静与沉着。
      </p>
      <div className="layout-news-center">
        <img src={process.env.PUBLIC_URL + "/IMG_202311210003.jpg"} alt="IMG" />
      </div>
      <div className="layout-news-center" style={{ "margin-top": "30px" }}>
        <img src={process.env.PUBLIC_URL + "/IMG_202311210004.jpg"} alt="IMG" />
      </div>
      <div className="layout-news-center" style={{ "margin-top": "30px" }}>
        <img src={process.env.PUBLIC_URL + "/IMG_202311210005.jpg"} alt="IMG" />
      </div>
      <div className="layout-news-center" style={{ "margin-top": "30px" }}>
        <img src={process.env.PUBLIC_URL + "/IMG_202311210006.jpg"} alt="IMG" />
      </div>
      <div className="layout-news-center" style={{ "margin-top": "30px" }}>
        <img src={process.env.PUBLIC_URL + "/IMG_202311210007.jpg"} alt="IMG" />
      </div>
      <div className="layout-news-center" style={{ "margin-top": "30px" }}>
        <img src={process.env.PUBLIC_URL + "/IMG_202311210008.jpg"} alt="IMG" />
      </div>
      <div className="layout-news-center" style={{ "margin-top": "30px" }}>
        <img src={process.env.PUBLIC_URL + "/IMG_202311210009.jpg"} alt="IMG" />
      </div>
      <div className="layout-news-center" style={{ "margin-top": "30px" }}>
        <img src={process.env.PUBLIC_URL + "/IMG_202311210010.jpg"} alt="IMG" />
      </div>
      <div className="layout-news-center" style={{ "margin-top": "30px" }}>
        <img src={process.env.PUBLIC_URL + "/IMG_202311210011.jpg"} alt="IMG" />
      </div>
      <div className="layout-news-center" style={{ "margin-top": "30px" }}>
        <img src={process.env.PUBLIC_URL + "/IMG_202311210012.jpg"} alt="IMG" />
      </div>
      <p>
        总经理、总监十分重视本次防火演练，不但列席还亲自参加逃生和灭火器的实战演练。透过本次应急预案的演练，充分体现了公司上下全体员工的精神面貌，加深了广大员工对消防安全知识的理解和消防器材正确使用的实战技能，使员工清晰的认识到如何应对突发的紧急状况，为公司安全生产工作的开展，起到了巩固作用。实现了“科学、安全、有序、快速”应对火灾事故的目标，强化了员工在火灾中应急逃生的方法，提高了各部门应对突发事件的处置潜力，取得了预期的演练效果。本次演练获得开投物业组织部门的高度赞许。
      </p>
      <div className="layout-news-center">
        <img src={process.env.PUBLIC_URL + "/IMG_202311210013.jpg"} alt="IMG" />
      </div>
      <div className="layout-news-center" style={{ "margin-top": "30px" }}>
        <img src={process.env.PUBLIC_URL + "/IMG_202311210014.jpg"} alt="IMG" />
      </div>
      <div className="layout-news-center" style={{ "margin-top": "30px" }}>
        <img src={process.env.PUBLIC_URL + "/IMG_202311210015.jpg"} alt="IMG" />
      </div>
      <div className="layout-news-center" style={{ "margin-top": "30px" }}>
        <img src={process.env.PUBLIC_URL + "/IMG_202311210016.jpg"} alt="IMG" />
      </div>
      <p>
        减少事故给公司带来的损失是每个员工义务和职责。公司员工和各级管理人员都务必密切配合处理突发事件，一旦接到处理突发事件的指令后，在确保自身安全的状况下要义不容辞的快速执行。不得以任何借口推托职责或拒绝执行。这样我们的公司才是一个有战斗力和执行力的团队。
      </p>
      <div className="layout-news-center">
        <img src={process.env.PUBLIC_URL + "/IMG_202311210017.jpg"} alt="IMG" />
      </div>
      <p>浙江盈烁新材料有限责任公司 办公室</p>
      <p>2023 年11 月21 日</p>
    </div>
  )
}

const News20231130 = ({ title }) => {
  return (
    <div className="layout-news-content">
      <h1>{title}</h1>
      <div className="layout-news-center">
        <img src={process.env.PUBLIC_URL + "/IMG_202311300002.jpg"} style={{ "width": "500px", "margin": "0 auto" }} alt="IMG" />
      </div>
      <p>
        为丰富员工业余生活，营造积极向上的职工文化生活氛围，加深各部门职工间彼此认识，促进团队协作，激发顽强拼搏精神，公司于2023年11月30日上午举办了首届趣味运动会。
      </p>
      <div className="layout-news-center">
        <img src={process.env.PUBLIC_URL + "/IMG_202311300003.jpg"} style={{ "width": "200px" }} alt="IMG" />
        <img src={process.env.PUBLIC_URL + "/IMG_202311300004.jpg"} style={{ "width": "200px" }} alt="IMG" />
        <img src={process.env.PUBLIC_URL + "/IMG_202311300005.jpg"} style={{ "width": "200px" }} alt="IMG" />
        <img src={process.env.PUBLIC_URL + "/IMG_202311300006.jpg"} style={{ "width": "200px" }} alt="IMG" />
      </div>
      <p>
        本次趣味运动会以团体项目为主，共设两人三足、托球跑、拔河、袋鼠跳、接力赛跑等10个比赛项目。运动会开始前，总经理发表了热情洋溢的动员致辞，并代表公司高层预祝这次运动会圆满成功。
      </p>
      <div className="layout-news-center">
        <img src={process.env.PUBLIC_URL + "/IMG_202311300001.jpg"} style={{ "width": "500px", "margin": "0 auto" }} alt="IMG" />
      </div>
      <p>
        赛场上，每一个比赛项目都是智慧和体力的角逐，都是团结和协作的比拼，职工们发挥特长，积极踊跃参加，为了团队的荣誉奋力拼搏，互相加油助威，笑声不断，留下了无数让人回味无穷的亮点和精彩瞬间。
      </p>
      <div className="layout-news-center">
        <img src={process.env.PUBLIC_URL + "/IMG_202311300099.jpg"} style={{ "width": "500px", "margin": "0 auto" }} alt="IMG" />
      </div>
      <p>
        这次运动会让大家充分体验了运动的快乐、竞争的乐趣、参与的欣慰，同时也加强了职工之间的交流，联络了感情，更重要的是凝聚了人心、振奋了精神、激发了斗志，全体运动员们用实际行动展现了协作、共赢、拼搏、向上的盈烁风采。
      </p>
      <div className='layout-news-center'>
        <video src="https://insulcarb.com/static/media/sports.ep1.480p.mp4" controls>
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  )
}



export default News;
