import React from 'react';
import Swipe from './swipe';
import './index.css';

const certList = [
  { "img": '/INSUL-ISO9001_CN.jpg', "title": "GB/T19001-2016 idt IS09001:2015" },
  { "img": '/INSUL-ISO9001_EN.jpg', "title": "GB/T19001-2016 idt IS09001:2015" },
  { "img": '/RD-CENTER.jpg', "title": "研发证书" },
]

const Home = () => {
  return (
    <>
      <Swipe />
      <div className="layout-home">
        <div className="about-home-layout">
          <div className="about-home-layout-left">
            公司简介
          </div>
          <div className="about-home-layout-right">
            <p>
              浙江盈烁新材料责任有限公司成立于 2023 年，位于浙江省杭州市临平区，注册资金 2500 万。
            </p>
            <p>
              本项目投资1亿元，经营面积9000多平方, 主要生产各种半导体用保温碳毡，由具有十余年经验的数位资深业内专家发起，同时邀请了国外专家，采用国际先进技术，结合本土供应链,为国内半导体行业替代目前的进口材料做出贡献。
            </p>
          </div>
        </div>
        <div className="cert-layout">
          <h2>资质证书</h2>
          <div className="cert-layout-list">
            {
              certList.map((item, key) => {
                return (
                  <div className="cert-layout-item" key={key}>
                    <div className="cert-layout-item-img">
                      <a href={item.img} target='_blank' rel="noreferrer">
                        <img src={item.img} alt="img" />
                      </a>
                    </div>
                    <div className="cert-layout-item-content">
                    </div>
                  </div>
                )
              })
            }

          </div>
        </div>
      </div>

    </>
  )
}

export default Home;
