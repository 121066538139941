import React from 'react';
import './index.css';

const products = [
  { 'image': process.env.PUBLIC_URL + '/product.0.png', 'title': '标准板材' },
  { 'image': process.env.PUBLIC_URL + '/product.1.png', 'title': '加工筒' },
  { 'image': process.env.PUBLIC_URL + '/product.2.png', 'title': '加工板' }
];

const detailLeft = [
  "具有优异的隔热性能、高温工作环境下稳定的性能。",
  "优异的可加工性。",
  "各种表面处理，提供不同的表面处理差异化产品。",
  "较小的发尘量，降低污染。"
];

const detailRight = [
  "重量轻，可按照客户的需求进行加工，可操作性强，装配简单。",
  "隔热性能+表面处理、纯度等性能优异，可以根据使用条件调整热场温度分布。",
  "具有高纯度，降低使用环境的二次污染，防止杂质混入。"
]

const moreList = [
  ["表面处理", "防止发尘", "气密性", "防止消耗", "耐冲击"],
  ["无", "-", "-", "-", "-"],
  ["G石墨涂层", <i className='circle'></i>, "-", <i className='circle'></i>, "-"],
  ["F石墨纸", <i className='circle'></i>, <i className='circle'></i>, <i className='circle'></i>, "-"],
  ["C碳纤维布", <i className='circle'></i>, "-", <i className='circle'></i>, <i className='circle'></i>],
]

const Product = () => {
  return (
    <div className='layout-products'>
      <div className='layout-products-container'>
        <h1>产品信息</h1>
        <div className='layout-products-list'>
          {
            products.map((item, index) => {
              return (
                <div className='layout-product' key={index}>
                  <div className='layout-product-title'>
                    <h2>{item.title}</h2>
                  </div>
                  <div className='layout-product-img'>
                    <img src={item.image} alt={item.image} />
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="layout-product-detail">
          <div className="layout-product-detail-title">
            <h2>产品特点</h2>
          </div>
          <div className="layout-product-detail-container">
            <ul>
              {
                detailLeft.map((item, index) => {
                  return (
                    <li key={index}>
                      <i className='circle'>{index}</i><span>{item}</span>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className="layout-product-detail-container">
            <ul>
              {
                detailRight.map((item, index) => {
                  return (
                    <li key={index}>
                      <i className='circle'></i><span>{item}</span>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
        <div className="layout-product-more">
          <table>
            {
              moreList.map((list, index) => {
                return (
                  <tr key={index}>{
                    list.map((item, idx) => {
                      return (
                        <td key={idx}>{item}</td>
                      )
                    })
                  }</tr>
                )
              })
            }
          </table>
        </div>
      </div>
    </div>
  )
}

export default Product;
