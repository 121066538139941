import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import './index.css';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'zh');

  const changeLanguage = () => {
    if (language === 'zh') {
      setLanguage('en');
      i18n.changeLanguage('en');
      localStorage.setItem('language', 'en');
    } else {
      setLanguage('zh');
      i18n.changeLanguage('zh');
      localStorage.setItem('language', 'zh');
    }
    window.location.reload();
  };

  return (
    <div className="header">
      <div className="logo">
        <a href="/"><img src="/insulcarb.png" alt='INSULCARB' /></a>
      </div>
      <div className="nav">
        <ul>
          <li><a href="/">{t('header.home')}</a></li>
          <li><a href="/about">{t('header.about')}</a></li>
          <li><a href="/product">{t('header.product')}</a></li>
          <li><a href="/service">{t('header.service')}</a></li>
          <li><a href="/news">{t('header.news')}</a></li>
          <li><a href="/contact">{t('header.contact')}</a></li>
        </ul>
      </div>
      <div className="lang-selector" onClick={() => changeLanguage()}>
        <span className={`lang-item ${language === 'zh' && 'active'}`}>中</span>
        <span> / </span>
        <span className={`lang-item ${language === 'en' && 'active'}`}>EN</span>
      </div>
    </div>
  )
}

export default Header;
